.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background: rgb(33,38,50);
  background: linear-gradient(45deg, rgba(33,38,50,1) 0%, rgba(73,77,90,1) 85%); 
  /* background-color: #ffffff; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
